@media screen and (min-width:993px) {
  .wrapper {
    padding-left: 78px;
  }
}
.sidebar {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 78px;
  height: 100vh;
  background: #002B49;
  &__basket {
    width: 100%;
    height: 90px;
    background: #007BD3;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    span {
      position: absolute;
      left: 50%;
      bottom: -20px;
      transform: translate(-50%, 0);
      width: 45px;
      height: 45px;
      background: #46B1FF;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
      color: #fff;
    }
  }
  &__search {
    width: 56px;
    height: 56px;
    background: #003760;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 60px auto 0;
  }
  &__languages {
    background: #003760;
    border-radius: 90px;
    margin: 6px auto 0;
    width: 56px;
    min-height: 118px;
    display: flex;
    justify-content: center;
    align-items: center;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
  }
  &__language {
    font-weight: 900;
    font-size: 15px;
    line-height: 120%;
    color: #005595;
    &:not(:first-child) {
      margin-top: 12px;
    }
    &--active {
      color: #fff;
    }
    &:hover {
      color: #fff;
    }
  }
  &__img {
    margin: 25px auto 35px;
    &-mob {
      display: none;
    }
  }
  &__btn {
    height: 266px;
    width: 56px;
    margin: 0 auto;
    span {
      writing-mode: vertical-rl;
      transform: rotate(180deg);
    }
  }
}
.main {
  padding: 175px 0 0;
  color: #fff;
  background-image: url("../img/main-bg.webp");
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 2;
  min-height: 800px;
  overflow: hidden;
  &__img-bg {
    position: absolute;
    left: 0;
    bottom: -620px;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 2;
  }
  &__left {
    width: 420px;
    margin-right: 30px;
    padding-top: 50px;
  }
  &__right {
    width: calc(100% - 450px);
  }
  &__subtitle {
    font-weight: 900;
    font-size: 24px;
    line-height: 120%;
  }
  &__title {
    font-weight: 900;
    font-size: 50px;
    line-height: 100%;
    text-transform: uppercase;
    margin-top: 18px;
  }
  &__info {
    font-size: 24px;
    line-height: 120%;
    margin-top: 18px;
  }
  &__img {
    margin-right: -100px;
    img {
      width: 100%;
    }
  }
}
.equipment {
  padding: 15px 0 40px 0;
  position: relative;
  z-index: 2;
  overflow: hidden;
  background-size: cover;
  &__img-bg {
    position: absolute;
    top: 200px;
    right: -50px;
    pointer-events: none;
  }
  &__img-bg2 {
    position: absolute;
    left: -250px;
    bottom: 550px;
    transform: rotate(180deg);
    pointer-events: none;
  }
  &__img-bg3 {
    position: absolute;
    z-index: -1;
    left: 50%;
    bottom: -23%;
    transform: translate(-50%, 0);
    width: 100%;
    height: 1000px;
  }
  &__img-bg4 {
    position: absolute;
    z-index: -2;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
  }
  &__title {
    text-transform: uppercase;
  }
  &__cards {
    margin-top: 50px;
    &-bottom {
      margin-top: 40px;
    }
    &-title {
      font-weight: 900;
      font-size: 26px;
      line-height: 120%;
      color: #000000;
      margin-bottom: 25px;
    }
    &-top {
      margin-top: 25px;
      position: relative;
    }
  }
  &__btn {
    max-width: 280px;
    height: 64px;
    padding: 0 15px;
    margin: 40px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-btns {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-button-prev {
    position: static;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #95DB35;
    border-radius: 4px;
    &:after {
      display: none;
    }
  }
  .swiper-button-next {
    position: static;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #95DB35;
    border-radius: 4px;
    margin-left: 15px;
    &:after {
      display: none;
    }
  }
  .swiper-slide {
    width: 100% !important;
  }
  @media screen and (min-width: 576px) {
    .swiper {
      overflow: unset;
    }
    .swiper-wrapper {
      display: grid;
      grid-template-columns: repeat(2,calc((100% / 2) - (22px * 1 / 2)));
      gap: 30px;
      transform: unset !important;
    }
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }
  &__img {
    position: relative;
    z-index: -2;
    max-width: calc(100% - 400px);
    margin: 70px auto 0;
    padding: 0 15px;
    img {
      width: 100%;
    }
  }
}
.certificates{
  padding: 42px 0 45px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  &__top{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__title{
    font-weight: 900;
    font-size: 26px;
    line-height: 32px;
    position: relative;
    display: flex;
    align-items: center;
    &:after{
      content: '';
      width: 180px;
      height: 3px;
      background-color:  #E2E2E2;
      flex-shrink: 0;
      margin: 0 30px;
    }
  }
  &__nav{
    display: flex;
    align-items: center;
  }
  .swiper-container {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow: hidden;

  }
  .swiper {
    &-button{
      position: static;
    }
  }
  .swiper-slide {
    background-position: center;
    background-size: cover;
    padding: 15px;
    background: #E8F2F1;
    box-shadow: 0px 20px 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    width: 20%;
    img {
      display: block;
      width: 100%;
    }
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right {
      background-image: none;
    }
  }
}
.bg-block {
  background-image: url("../img/bg-block.webp");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  &__img {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1200px;
    pointer-events: none;
  }
  &__img2 {
    position: absolute;
    z-index: -1;
    top: 50px;
    left: 0;
    pointer-events: none;
  }
  &__img3 {
    position: absolute;
    z-index: -1;
    top: 50px;
    right: 0;
    pointer-events: none;
  }
}
.about{
  padding: 10px 0 42px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  &__title,
  &__inner{
    position: relative;
    z-index: 2;
  }
  &__title{
    text-transform: uppercase;
  }
  &__inner{
    padding: 45px 50px 48px;
    background-image: url(../img/about-bg.webp);
    background-size: cover;
    background-position: center;
    margin-top: 31px;
    color: #FFFFFF;
  }
  &__desc1,
  &__desc2{
    font-size: 18px;
    line-height: 120%;
  }
  &__desc1{
    font-weight: 700;
    margin-bottom: 15px;
  }
  &__nav{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  &__btn{
    padding: 0 35px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.exhibition{
  padding: 45px 0 90px;
  position: relative;
  z-index: 2;
  overflow: hidden;
  &__slider{
    margin-top: 60px;
  }
  &__nav{
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
  &__btn{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 56px;
    padding: 0 36px;
  }
}
.exhibition-card{
  position: relative;
  display: block;
  &__img{
    padding-bottom: 61%;
    position: relative;
    filter: drop-shadow(3px 4px 20px rgba(0, 0, 0, 0.1));
    border-radius: 4px;
    &:before{
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: calc(100% - 53px);
      background: linear-gradient(360deg, #398697 0%, rgba(255, 255, 255, 0) 100%);
      border-radius: 4px;
      z-index: 2;
    }
    img{
      border-radius: inherit;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__content{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 30px;
    z-index: 2;
    color: #FFFFFF;
  }
  &__date{
    font-size: 14px;
    line-height: 120%;
  }
  &__title{
    margin-top: 9px;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    min-height: 44px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp:2;
    -webkit-box-orient: vertical;
  }
}
.reviews {
  padding: 90px 0;
  position: relative;
  z-index: 2;
  overflow: hidden;
  &__inner {
    position: relative;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__line {
    margin: 0 30px;
    svg {
      width: 100%;
    }
  }
  &__btn {
    width: 239px;
    height: 56px;
    position: relative;
    z-index: 2;
    flex-shrink: 0;
  }
  &__slider {
    position: relative;
    margin-top: 60px;
  }
  .swiper-slide {
    text-align: center;
  }
  &__slide {
    &-img {
      background: #E8F2F1;
      box-shadow: 0px 20px 10px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      padding: 15px;
      display: block;
      img {
        width: 100%;
      }
    }
    &-desc {
      font-size: 18px;
      line-height: 100%;
      color: #000000;
      margin-top: 35px;
    }
  }
  &__nav {
    justify-content: flex-end;
    display: none;
    .swiper-button-prev {
      position: static;
    }
    .swiper-button-next {
      position: static;
    }
  }
}
.contacts {
  color: #fff;
  position: relative;
  height: 535px;
  .map {
    height: 535px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__inner {
    position: relative;
    z-index: 2;
    max-width: 375px;
    height: 100%;
    margin: 0 0 0 auto;
  }
  &__content {
    background-image: url("../img/contacts-bg.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    max-width: 470px;
    padding: 40px 40px 80px;
    margin: 75px 0 0 auto;
  }
  &__title {
    color: #fff;
    span {
      padding-left: 10px;
      background: linear-gradient(90deg, #1D98B0 -26.92%, rgba(255, 255, 255, 0.3) 118.27%);
    }
  }
  &__socials {
    margin-top: 50px;
  }
  &__social {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 27px;
    }
    &-icon {
      width: 25px;
      img {
        width: 100%;
      }
    }
    &-desc {
      margin-left: 18px;
    }
    &-title {
      font-size: 14px;
      line-height: 120%;
    }
    &-text {
      font-weight: 700;
      font-size: 18px;
      line-height: 120%;
    }
  }
}


@media screen and (max-width: 1900px) {
  .equipment {
    &__img {
      max-width: 1400px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .main {
    &__img {
      margin-right: 0;
    }
  }
  .bg-block {
    &__img2,
    &__img3 {
      display: none;
    }
  }
}

@media screen and (max-width: 1400px) {
  .equipment {
    &__img-bg3 {
      bottom: -27%;
    }
  }
}

@media screen and (max-width: 1300px) {
 .certificates {
   .swiper-slide {
      width: 33%;
   }
 }
}

@media screen and (max-width: 1200px) {
  .main {
    padding: 125px 0 0 0;
    min-height: 600px;
    &__img-bg {
      bottom: -680px;
    }
    &__left {
      width: 300px;
    }
    &__right {
      width: calc(100% - 330px);
    }
    &__subtitle {
      font-size: 16px;
    }
    &__title {
      font-size: 34px;
      margin-top: 5px;
    }
    &__info {
      font-size: 16px;
      margin-top: 5px;
    }

  }
  .equipment {
    &__img-bg {
      width: 250px;
      height: 470px;
      top: 500px;
      right: 0;
    }
    &__img-bg2 {
      width: 250px;
      height: 470px;
      top: 300px;
      left: 0;
      bottom: unset;
    }
  }
  .contacts {
    &__inner {
      position: static;
    }
    &__content {
      width: calc(100% - 4%);
      max-width: 100%;
      padding: 40px 40px 20px 40px;
      position: absolute;
      left: 50%;
      bottom: -105px;
      transform: translate(-50%,0);
      margin: 0 auto;
    }
    &__socials {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
    &__social {
      margin: 0 25px 25px 0;
    }
  }
}

@media screen and (max-width: 992px) {
  .sidebar {
    position: fixed;
    top: unset;
    bottom: 0;
    width: 100%;
    height: 78px;
    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
    }
    &__basket {
      width: 78px;
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 35px;
      img {
        margin-right: 20px;
      }
      span {
        left: unset;
        top: 50%;
        right: -20px;
        transform: translate(0, -50%);
      }
    }
    &__search {
      width: 46px;
      height: 46px;
      margin: auto 15px;
    }
    &__languages {
      width: 187px;
      height: 47px;
      min-height: unset;
      writing-mode: unset;
      transform: unset;
      justify-content: space-between;
      padding: 0 25px;
      margin: 0 15px;
    }
    &__language {
      &:not(:first-child) {
        margin: 0 0 0 12px;
      }
    }
    &__img {
      display: none;
      &-mob {
        display: block;
        margin: 0 15px;
      }
    }
    &__btn {
      width: 256px;
      height: 47px;
      margin: 0 15px 0 0;
      span {
        writing-mode: unset;
        transform: unset;
      }
    }
  }
  .main {
    padding: 100px 0 0;
    min-height: 500px;
    &__img-bg {
      bottom: -690px;
    }
    &__left {
      padding-top: 0;
    }
  }
  .equipment {
    padding: 30px 0 40px;
    &__img-bg3 {
      bottom: -10%;
      height: 450px;
    }
    .swiper-wrapper {
      gap: 15px;
    }
    &__cards {
      margin-top: 22px;
      &-title {
        font-size: 22px;
      }
      &-top {
        gap: 15px;
      }
      &-title {
        font-size: 22px;
      }
    }
  }
  .bg-block {
    &__img2,
    &__img3 {
      display: none;
    }
  }
  .certificates{
    padding: 30px 0 43px;
    &__title{
      flex-grow: 1;
      font-size: 22px;
      line-height: 27px;
      &:after{
        flex-grow: 1;
      }
    }
  }
  .about{
    padding-bottom: 30px;
    &__inner{
      padding: 35px 40px;
    }
  }
  .exhibition{
    padding: 45px 0 60px;
  }
  .exhibition-card{
    &__img{
      padding-bottom: 55%;
    }
  }
  .reviews {
    padding: 60px 0 90px;
    &__slider {
      max-width: 570px;
      margin: 60px auto 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .sidebar {
    &__basket {
      margin-right: 25px;
    }

    &__search {
      margin: 0 10px;
    }

    &__languages {
      width: 140px;
      padding: 0 20px;
      margin: 0 10px;
    }

    &__img-mob {
      display: none;
    }
  }
  .main {
    &__left {
      width: 215px;
    }

    &__right {
      width: calc(100% - 245px);
    }

    &__subtitle {
      font-size: 14px;
    }

    &__title {
      font-size: 24px;
    }

    &__info {
      font-size: 14px;
    }
  }
  .equipment {
    &__cards {
      &-title {
        font-size: 18px;
      }
    }
  }
  .certificates{
    padding: 32px 0 37px;
    &__title{
      &:after{
        margin: 0 40px;
      }
    }
  }
  .about{
    padding-bottom: 32px;
    &__desc2{
      display: none;
    }
    &__nav{
      justify-content: center;
    }
    &__btn{
      height: 62px;
    }
  }
  .exhibition-card{
    &__img{
      padding-bottom: 63%;
    }
    &__content{
      padding: 25px;
    }
  }
  .contacts {
    &__content {
      bottom: -180px;
    }
  }
}

@media screen and (max-width: 576px) {
  .sidebar {
    &__languages {
      width: 95px;
      padding: 0 12px;
    }
    &__search {
      flex-shrink: 0;
    }
    &__language {
      font-size: 12px;
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
    &__btn {
      width: 125px;
      font-size: 13px;
    }
  }
  .main {
    &__img-bg {
      bottom: -500px;
    }
    &__inner {
      flex-direction: column;
    }
    &__left {
      width: 100%;
      margin: 15px 0 0 0;
    }
    &__right {
      width: 100%;
      order: -1;
    }
    &__subtitle {
      color: #000;
      font-size: 16px;
    }
    &__title {
      color: #000;
      font-size: 30px;
    }
    &__info {
      color: #000;
      font-size: 16px;
    }
  }
  .equipment {
    padding: 40px 0 20px 0;
    overflow: hidden;
    &__img-bg,
    &__img-bg2 {
      display: none;
    }
    &__img-bg3 {
      height: 320px;
      bottom: -13%;
    }
    &__title {
      text-align: center;
    }
    &__cards-title {
      font-size: 16px;
      text-align: center;
    }
    &__btn {
      display: none;
    }
    .swiper {
      overflow: unset;
    }
    .swiper-wrapper {
      align-items: stretch;
      gap: unset;
    }
    .swiper-slide {
      height: auto;
      opacity: 0;
      transition: all .3s;
      &-active,
      &-next,
      &-next + .swiper-slide{
        opacity: 1;
        pointer-events: unset;
      }
    }
    .swiper-btns {
      margin-top: 50px;
    }
    &__img {
      margin-top: 20px;
    }
  }
  .certificates{
    padding: 25px 0;
    &__title{
      text-align: center;
      display: block;
      font-size: 18px;
      line-height: 22px;
      &:after{
        display: none;
      }
    }
    &__nav{
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .swiper-slide {
      width: 50%;
    }
  }
  .about{
    &__inner{
      padding: 35px 28px 25px;
    }
    &__desc1 {
      font-size: 16px;
    }
  }
  .exhibition{
    padding: 45px 0;
    &__slider{
      margin-top: 27px;
    }
    &__btn{
      width: calc(100% - 60px);
      padding: 0;
    }
  }
  .reviews {
    padding: 45px 0 130px;
    &__title {
      &:after {
        width: 68px;
      }
    }
    &__btn {
      position: absolute;
      left: 50%;
      bottom: -86px;
      transform: translate(-50%,0);
      &:after {
        display: none;
      }
      &:before {
        display: none;
      }
    }
    &__nav {
      display: flex;
      position: relative;
    }
    &__slider {
      margin-top: 30px;
    }
    &__slide {
      &-desc {
        font-size: 16px;
      }
    }
  }
  .contacts {
    height: 365px;
    .map {
      height: 365px;
    }
    &__inner {
      height: 365px;
    }
    &__content {
      bottom: -250px;
    }
  }
}

@media screen and (max-width: 400px) {
  .sidebar {
   display: none;
 }
  .equipment {
    &__cards-bottom {
      margin-top: 15px;
    }
  }
  .certificates{
    padding: 32px 0 23px;
    &__inner{
      position: relative;
      padding-bottom: 40px;
    }
    &__top{
      display: block;
    }
    .swiper-container {
      padding-top: 30px;
    }
    .swiper-slide {
      width: 100%;
    }
  }
  .about{
    &__inner{
      padding: 37px 30px 30px;
      margin-top: 35px;
    }
    &__btn{
      width: 100%;
    }
  }
  .contacts {
    &__content {
      padding: 15px;
    }
  }
}
