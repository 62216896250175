.footer {
  background: #002B49;
  padding: 80px 0 30px 0;
  position: relative;
  color: #fff;
  &__img-bg {
    position: absolute;
    z-index: 0;
    left: -50px;
    bottom: -50px;
    pointer-events: none;
  }
  &__img-bg2 {
    position: absolute;
    z-index: 0;
    right: -50px;
    bottom: -50px;
    pointer-events: none;
  }
  &__img-bg3 {
    position: absolute;
    right: 140px;
    bottom: 20px;
    pointer-events: none;
    display: none;
  }
  &__inner {
    position: relative;
    z-index: 2;
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    margin-bottom: 50px;
    &:not(:first-child) {
      margin-left: 15px;
    }
    &--flex {
      display: flex;
    }
  }
  &__logo {
    width: 212px;
    display: block;
    img {
      width: 100%;
    }
  }
  &__subtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-transform: uppercase;
    color: #187AC0;
    margin-bottom: 15px;
  }
  &__list {
    max-width: 182px;
    margin-left: 20px;
    li {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      text-transform: uppercase;
      &:not(:first-child) {
        margin-top: 15px;
      }
      &:hover {
        color: #95db35;
      }
    }
  }
  &__list2 {
    max-width: 192px;
    li {
      font-size: 16px;
      line-height: 120%;
      &:not(:first-child) {
        margin-top: 15px;
      }
      &:hover {
        color: #95db35;
      }
    }
  }
  &__brevis {
    max-width: 350px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    &-img {
      width: 44px;
      flex-shrink: 0;
      img {
        width: 100%;
      }
    }
    &-desc {
      font-size: 16px;
      line-height: 120%;
      margin-left: 12px;
      a {
        text-decoration: underline;
        &:hover {
          color: #95db35;
        }
      }
    }
  }
}


@media screen and (max-width: 1200px) {
  .footer {
    margin-top: 150px;
    &2 {
      margin: 0;
    }
  }
}

@media screen and (max-width: 992px) {
  .footer {
    &__img-bg {
      display: none;
    }
    &__img-bg2 {
      display: none;
    }
    &__img-bg3 {
      display: block;
    }
    &__item {
      &:not(:first-child) {
        margin-left: 45px;
      }
      &--flex {
        display: block;
      }
    }
    &__list {
      margin: 50px 0 0 0;
      padding-left: 20px;
    }
  }
}



@media screen and (max-width: 768px) {
  .footer {
    padding: 60px 0;
    margin-top: 240px;
    &2 {
      margin: 0;
    }
    &__img-bg3 {
      top: 110px;
      right: -30px;
      transform: rotate(-90deg);
    }
    &__items {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
    }
    &__item {
      margin: 0;
      &:not(:first-child) {
        margin: 0;
      }
      &--flex {
        grid-column: 1/3;
      }
      &--100 {
        grid-column: 1/3;
      }
    }
    &__list {
      max-width: 100%;
      padding-left: 0;
    }
    &__list2 {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 576px) {
  .footer {
    margin-top: 300px;
    &2 {
      margin: 0;
    }
    &__items {
      gap: 30px 15px;
    }
    &__list2 {
      li {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .footer {
    &__items {
      grid-template-columns: 1fr;
    }
    &__item {
      &--flex {
        grid-column: 1/2;
      }
      &--100 {
        grid-column: 1/2;
      }
    }
  }
}
