.nav {
  padding: 50px;
  &__list {
    a {
      font-size: 28px;
      line-height: 120%;
      color: #000;
      display: block;
      &:not(:first-child) {
        margin-top: 25px;
      }
      &:hover {
        color: #95db35;
      }
    }
  }
  &-btn {
    margin-top: 30px;
    width: 300px;
    height: 56px;
  }
}
.breadcrumbs {
  margin: 0 0 40px 0;
  &__items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  &__item {
    font-size: 14px;
    line-height: 120%;
    color: #636363;
    margin-top: 20px;
    position: relative;
    &:not(:last-child) {
      margin-right: 25px;
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: -14px;
        transform: translate(0, -50%);
        width: 3px;
        height: 5px;
        background-image: url("../img/arrow.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    }
    &--active {
      color: #000;
    }
    &:hover {
      color: #000;
    }
  }
}
.btn-green {
  background: #95DB35;
  border-radius: 4px;
  font-weight: 900;
  font-size: 15px;
  line-height: 120%;
  border: 1px solid #95db35;
  color: #fff;
  &:hover {
    background: #fff;
    border: 1px solid #95db35;
    color: #95db35;
  }
}
.display2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 100%;
  color: #000000;
  text-transform: uppercase;
  span {
    background: linear-gradient(90deg, #1D98B0 -26.92%, rgba(255, 255, 255, 0) 118.27%);
    border-radius: 90px;
  }
}
.equipment__card {
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  &:hover {
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.2);
  }
  &-left {
    width: calc(100% - 275px);
    display: flex;
    justify-content: center;
    img {
      //width: 100%;
      max-width: 100%;
      max-height: 265px;
    }
  }
  &-right {
    width: 245px;
    margin-left: 30px;
  }
  &-title {
    font-size: 16px;
    line-height: 120%;
    color: #000000;
  }
  &-list {
    margin-top: 28px;
    max-width: 195px;
    li {
      font-size: 11px;
      line-height: 100%;
      color: #636363;
      padding-left: 10px;
      position: relative;
      &:not(:first-child) {
        margin-top: 12px;
      }
      &:after {
        content: '';
        position: absolute;
        top: 4px;
        left: 0;
        width: 3px;
        height: 3px;
        background: #005492;
        border-radius: 50%;
      }
    }
  }
  &-link {
    display: inline-block;
    margin-top: 28px;
    font-weight: 900;
    font-size: 15px;
    line-height: 120%;
    color: #95DB35;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 1px;
      background: #95db35;
    }
  }
}
.form {
  &-group {
    margin: 0;
    padding: 0;
    border: none;
    input {
      font-size: 14px;
      line-height: 100%;
      color: #898989;
      background: #EFEFEF;
      border-radius: 4px;
      border: none;
      padding: 0 15px;
    }
    textarea {
      font-size: 14px;
      line-height: 100%;
      color: #898989;
      background: #EFEFEF;
      border-radius: 4px;
      border: none;
      resize: none;
      padding: 15px;
    }
  }
}
.swiper-button{
  width: 42px;
  height: 42px;
  flex-shrink: 0;
  margin: 0;
  background: #95db35;
  &:after{
    display: none;
  }
  &-prev{
    margin-right: 7px;
  }
}
.modal {
  background: rgba(#000, .4);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  &--active{
    opacity: 1;
    pointer-events: unset;
  }
  &__dialog{
    width: 450px;
    max-width: 100%;
    position: relative;
    background: #fff;
    padding: 30px;
    margin: 0 auto;
    @media screen and (max-width: 992px) {
      margin: 50px auto 0;
      max-width: calc(100% - 30px);
    }
  }
  @media screen and (min-width: 993px) {
    text-align: center;
    &:before {
      display: inline-block;
      vertical-align: middle;
      content: " ";
      height: 100%;
    }
    &__dialog {
      display: inline-block;
      text-align: left;
      vertical-align: middle;
    }
  }
  &__close{
    position: absolute;
    top: 0;
    right: -46px;
    width: 46px;
    height: 46px;
    background: #005492;
    border-radius: 0px 4px 4px 0px;
  }
  &__title {
    font-size: 24px;
  }
  &__form {
    margin-top: 25px;
    &-top {
      .modal__form-group {
        &:not(:first-child) {
          margin-top: 10px;
        }
        input {
          width: 100%;
          height: 50px;
        }
      }
    }
    &-bottom {
      margin-top: 25px;
      display: flex;
      align-items: center;
    }
    &-check {
      display: flex;
      align-items: center;
      position: relative;
      input {
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 100%;
        height: 100%;
        cursor: pointer;
        margin: 0;
        padding: 0;
        opacity: 0;
        &:checked ~ label {
          &:before {
            opacity: 1;
          }
        }
      }
      label {
        font-size: 12px;
        line-height: 100%;
        text-decoration-line: underline;
        color: #8F8F8F;
        padding-left: 37px;
        br {
          display: none;
        }
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          width: 22px;
          height: 22px;
          background: #EFEFEF;
          border-radius: 4px;
        }
        &:before {
          content: '';
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 6px;
          transform: translate(0, -50%);
          width: 11px;
          height: 9px;
          background-image: url("../img/check.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 11px 9px;
          opacity: 0;
        }
      }
    }
    &-btn {
      width: 132px;
      height: 56px;
    }
  }
}
.up {
  position: fixed;
  z-index: 1000;
  bottom: 30px;
  right: 30px;
  width: 84px;
  height: 84px;
  background: #005492;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  &--active {
    cursor: pointer;
    opacity: 1;
    pointer-events: all;
  }
}

@media screen and (max-width: 1150px) {
  .equipment__card {
    &-left {
      width: calc(100% - 225px);
    }
    &-right {
      width: 210px;
      margin-left: 15px;
    }
  }
}

@media screen and (max-width: 992px) {
  .breadcrumbs {
    margin: 13px 0 20px;
    &__item {
      margin-top: 7px;
    }
  }
  .display2 {
    font-size: 30px;
  }
  .equipment__card {
    flex-direction: column;
    padding: 30px 15px;
    &-left {
      width: 100%;
      max-width: 320px;
      margin: 0 auto;
    }
    &-right {
      width: 100%;
      margin: 10px 0 0 0;
      text-align: center;
    }
    &-title {
      font-size: 16px;
    }
    &-link {
      font-size: 15px;
      margin-top: 30px;
    }
    &-list {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .display2 {
    font-size: 25px;
  }
  .modal {
    &__close {
      right: 0;
      width: 30px;
      height: 30px;
    }
    &__form-bottom {
      display: block;
    }
    &__form-btn {
      margin-top: 15px;
    }
  }
  .up {
    top: -102px;
  }
}

@media screen and (max-width: 576px) {
  .display2 {
    font-size: 22px;
  }
}