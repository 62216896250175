.header {
  color: #fff;
  &__wrapp {
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    padding-left: 78px;
    &2 {
      background-image: url("../img/header-bg.webp");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
  &-top {
    background: rgba(7, 100, 119, 0.59);
    padding: 5px 0;
    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__right {
      display: flex;
      align-items: center;
    }
    &__logo {
      width: 195px;
      display: block;
      img {
        width: 100%;
      }
    }
    &__logo-menu {
      font-weight: 700;
      font-size: 48px;
      line-height: 120%;
      text-transform: uppercase;
      background: linear-gradient(90deg, #1D98B0 0%, rgba(255, 255, 255, 0) 71.03%);
      border-radius: 90px;
      display: none;
      span {
        padding-left: 15px;
      }
    }
    &__socials {
      display: flex;
      align-items: center;
    }
    &__social {
      &:not(:first-child) {
        margin-left: 55px;
      }
      &-title {
        font-size: 14px;
        line-height: 120%;
        display: block;
      }
      &-link {
        font-weight: 700;
        font-size: 18px;
        line-height: 120%;
        position: relative;
        &:hover {
          color: #95db35;
        }
      }
      &-tel {
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: -27px;
          transform: translate(0, -50%);
          width: 15px;
          height: 15px;
          background-image: url("../img/tel.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
      }
      &-location {
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: -27px;
          transform: translate(0, -50%);
          width: 14px;
          height: 14px;
          background-image: url("../img/nav.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
      }
      &-mail {
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: -27px;
          transform: translate(0, -50%);
          width: 14px;
          height: 12px;
          background-image: url("../img/mail.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
      }
    }
    &__btn {
      margin-left: 12px;
      width: 188px;
      height: 56px;
    }
    &__burger {
      width: 19px;
      height: 8px;
      position: relative;
      z-index: 7;
      cursor: pointer;
      display: none;
      span {
        position: absolute;
        background: #fff;
        height: 2px;
        transition: all 0.5s;
        &:nth-child(1) {
          width: 100%;
          top: 0;
          left: 0;
        }
        &:nth-child(2) {
          width: 100%;
          left: 0;
          bottom: 0;
        }
      }
      &--active {
        span {
          &:nth-child(1) {
            transform: rotate(-45deg);
            top: 50%;
          }
          &:nth-child(2) {
            transform: rotate(45deg);
            top: 50%;
          }
        }
      }
    }
  }
  &-bottom {
    background: rgba(12, 47, 73, 0.7);
    padding: 15px 0;
    &__logo-menu {
      font-weight: 700;
      font-size: 48px;
      line-height: 120%;
      text-transform: uppercase;
      background: linear-gradient(90deg, #1D98B0 0%, rgba(255, 255, 255, 0) 71.03%);
      border-radius: 90px;
      display: none;
      span {
        padding-left: 15px;
      }
    }
    &__inner {
      position: relative;
      z-index: 2;
    }
    &__img {
      display: none;
    }
    &__list {
      display: flex;
      align-items: center;
    }
    &__link {
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      text-transform: uppercase;
      position: relative;
      a {
        &:hover {
          color: #95db35;
        }
      }
      &:not(:first-child) {
        margin-left: 60px;
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: -30px;
          transform: translate(0, -50%);
          width: 1px;
          height: 80%;
          background: #fff;
        }
      }
    }
    .header-bottom__link2 {
      transition: all 0.5s;
      cursor: pointer;
      position: relative;
      &:hover {
        color: #95db35;
      }
      &--active {
        .header-bottom__hidden {
          opacity: 1;
          pointer-events: unset;
        }
      }
    }
    &__show {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 4;
      i {
        display: flex;
        align-items: center;
        margin-left: 7px;
      }
    }
    &__hidden {
      position: absolute;
      z-index: 3;
      left: 0;
      top: 0;
      width: 866px;
      max-width: calc(63vw);
      color: #000;
      padding-top: 30px;
      pointer-events: none;
      opacity: 0;
      &-inner {
        width: 100%;
        padding: 30px;
        background: #fff;
        box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 0px 0px 4px 4px;
      }
      &-titles {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        &:not(:first-child) {
          margin-left: 15px;
        }
      }
    }
    &__items {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      margin-top: 15px;
    }
    &__item {
      display: flex;
      align-items: center;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 0;
        height: 1px;
        background: #95db35;
        transition: all 0.5s;
      }
      &:hover {
        &:after {
          width: 100%;
        }
      }
      &-desc {
        font-size: 16px;
        line-height: 120%;
        font-weight: 400;
        text-transform: none;
        margin-left: 10px;
      }
      &-img {
        width: 60px;
        flex-shrink: 0;
        img {
          width: 100%;
        }
      }
    }
  }
  &-mob {
    background: rgba(7, 100, 119, 0.59);
    padding: 5px 0 5px 78px;
    display: none;
    &2 {
      background-image: url("../img/header-bg.webp");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(7, 100, 119, 0.59);
      }
    }
    &__logo {
      width: 195px;
      display: block;
      position: relative;
      z-index: 2;
      img {
        width: 100%;
      }
    }
    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__burger {
      width: 25px;
      height: 10px;
      position: relative;
      z-index: 11;
      cursor: pointer;
      display: none;
      span {
        position: absolute;
        background: #fff;
        height: 3px;
        transition: all 0.5s;
        &:nth-child(1) {
          width: 100%;
          top: 0;
          left: 0;
        }
        &:nth-child(2) {
          width: 100%;
          left: 0;
          bottom: 0;
        }
      }
      &--active {
        span {
          &:nth-child(1) {
            transform: rotate(-45deg);
            top: 50%;
          }
          &:nth-child(2) {
            transform: rotate(45deg);
            top: 50%;
          }
        }
      }
    }
  }
}
.bg-blue {
  background: rgba(7, 100, 119, 1);
}

@media screen and (max-width: 1300px) {
  .header {
    &-top {
      &__logo {
        width: 150px;
      }
      &__social {
        &-link {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .header {
    &-bottom {
      &__link {
        &:not(:first-child) {
          margin-left: 40px;
          &:after {
            left: -20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 4;
    &--active {
      z-index: 6;
    }
    &__wrapp {
      position: absolute;
      z-index: 10;
      left: -100%;
      bottom: 0;
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      background: #002B49;
      padding: 30px 75px 50px 75px;
      transition: all 0.5s;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background: #000;
        border-radius: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background: #95db35;
        border-radius: 3px;
      }
      &--active {
        left: 0;
      }
    }
    &-mob {
      display: block;
      &__burger {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          z-index: 2;
        }
        &--active {
          background: #005492;
          top: -23px;
          right: 0;
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 24px;
            background: #005492;
          }
        }
      }
    }
    &-top {
      background: transparent;
      padding: 0;
      order: 2;
      .container {
        padding: 0;
      }
      &__inner {
        padding-top: 40px;
        display: block;
      }
      &__left {
        display: none;
      }
      &__right {
        display: block;
      }
      &__socials {
        display: block;
        padding-left: 43px;
      }
      &__social {
        &:not(:first-child) {
          margin: 30px 0 0 0;
        }
        &-tel {
          font-size: 32px;
          &:after {
            left: -43px;
            width: 25px;
            height: 25px;
          }
        }
        &-location {
          font-size: 18px;
          &:after {
            left: -43px;
            width: 25px;
            height: 25px;
          }
        }
        &-mail {
          font-size: 18px;
          &:after {
            left: -43px;
            width: 25px;
            height: 21px;
          }
        }
      }
      &__btn {
        margin: 45px 0 0 0;
      }
    }
    &-bottom {
      background: transparent;
      padding: 0;
      .container {
        padding: 0;
      }
      &__inner {
        padding-bottom: 40px;
        border-bottom: 2px solid #053F68;
      }
      &__img {
        display: block;
        position: absolute;
        z-index: -1;
        top: 0;
        right: 125px;
      }
      &__logo-menu {
        display: inline-block;
        margin-bottom: 45px;
      }
      &__list {
        display: block;
      }
      &__link {
        font-size: 18px;
        &:not(:first-child) {
          margin: 30px 0 0 0;
          &:after {
            display: none;
          }
        }
      }

    }
  }
}

@media screen and (max-width: 992px) {
  .header {
    &-mob {
      padding: 5px 0;
      &__logo {
        width: 123px;
      }
      &__burger {
        &--active {
          top: -8px;
        }
      }
    }
    &-top {
      &__btn {
        width: 100%;
        height: 64px;
      }
    }
    &-bottom {
      &__hidden {
        max-width: calc(80vw);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    &__wrapp {
      padding: 30px 45px 45px;
    }
    &-bottom {
      &__img {
        right: 55px;
      }
      &__hidden {
        max-width: calc(88vw);
        &-titles {
          display: block;
        }
        &-title {
          &:not(:first-child) {
            margin: 15px 0 0 0;
          }
        }
        &-inner {
          max-height: 550px;
          overflow-y: auto;
          padding: 30px 15px;
          &::-webkit-scrollbar {
            width: 4px;
          }
          &::-webkit-scrollbar-track {
            background: #000;
            border-radius: 3px;
          }
          &::-webkit-scrollbar-thumb {
            background: #95db35;
            border-radius: 3px;
          }
        }
      }
      &__items {
        grid-template-columns: 1fr;
        gap: 20px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .header {
    &-bottom {
      &__img {
        width: 168px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .header {
    &__wrapp {
      padding: 30px 30px 45px;
    }
    &-bottom {
      &__img {
        width: 110px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .header {
    &-top {
      &__socials {
        padding-left: 26px;
      }
      &__social {
        &-link {
          font-size: 18px;
        }
        &-tel {
          &:after {
            left: -26px;
            width: 15px;
            height: 15px;
          }
        }
        &-location {
          &:after {
            left: -26px;
            width: 15px;
            height: 15px;
          }
        }
        &-mail {
          &:after {
            left: -26px;
            width: 15px;
            height: 11px;
          }
        }
      }
    }
  }
}

