@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  src: url('../fonts/Montserrat-Light.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  src: url('../fonts/Montserrat-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url('../fonts/Montserrat-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url('../fonts/Montserrat-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url('../fonts/Montserrat-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url('../fonts/Montserrat-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: url('../fonts/Montserrat-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: url('../fonts/Montserrat-SemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url('../fonts/Montserrat-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url('../fonts/Montserrat-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  src: url('../fonts/Montserrat-SemiExtraBold.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  src: url('../fonts/Montserrat-SemiExtraBold.woff2') format('woff2');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  src: url('../fonts/Montserrat-Black.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  src: url('../fonts/Montserrat-Black.woff2') format('woff2');
}