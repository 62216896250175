.content2 {
  background-image: url("../img/bg-block4.webp");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  position: relative;
  &-img {
    position: absolute;
    z-index: 0;
    right: 0;
    bottom: 200px;
    pointer-events: none;
  }
  &-img2 {
    position: absolute;
    z-index: 0;
    top: 400px;
    left: 0;
    pointer-events: none;
  }
}
.equipment-main {
  padding: 180px 0 60px;
  &__title {
    span {
      background: linear-gradient(90deg, #1D98B0 -26.92%, rgba(255, 255, 255, 0) 90%);
    }
  }
  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 40px;
    margin-top: 30px;
    position: relative;
    overflow: hidden;
    z-index: 3;
  }
  &__left {
    max-width: calc(100% - 385px);
  }
  &__right {
    max-width: 325px;
    margin-left: 60px;
  }
  .gallery {
    display: flex;
    align-items: center;
    max-height: 601px;
    &__big {
      max-width: 550px;
      margin-right: 30px;
      margin-left: 0;
      height: 100%;
      .swiper-slide {
        margin: 0 !important;
      }
      .swiper-slide {
        img {
          width: 100%;
        }
      }
    }
    &__small {
      margin: 0;
      height: 100%;
      max-height: 440px;
      overflow: unset;
      .swiper {
        &-slide{
          background: #FFFFFF;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          width: 100%;
          opacity: 0;
          pointer-events: none;
          transition: all .3s;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px 10px;
          img {
            max-width: 100%;
          }
          &-active,
          &-next,
          &-next + .swiper-slide{
            opacity: 1;
            pointer-events: unset;
          }
        }
      }
    }
  }
  .gallery__small {
    &-wrapp {
      position: relative;
      width: 130px;
      flex-shrink: 0;
      .swiper-button-prev {
        width: 30px;
        height: 30px;
        background: #95DB35;
        border-radius: 4px;
        left: 50%;
        top: -20px;
        transform: translate(-50%, 0);
        &:after {
          display: none;
        }
      }
      .swiper-button-next {
        width: 30px;
        height: 30px;
        background: #95DB35;
        border-radius: 4px;
        left: 50%;
        top: unset;
        bottom: -40px;
        transform: translate(-50%, 0);
        &:after {
          display: none;
        }
      }
    }
  }
  .catalog-item__gallery {
    height: 100%;
    display: block;
    img {
      max-height: 100%;
    }
  }
  &__btn {
    width: 100%;
    height: 64px;
  }
  &__info {
    background: #F2F2F2;
    border-radius: 4px;
    margin-top: 45px;
    padding: 30px 40px;
    &-subtitle {
      font-weight: 900;
      font-size: 15px;
      line-height: 120%;
      color: #005492;
    }
    &-list {
      margin-top: 30px;
      li {
        font-size: 11px;
        line-height: 100%;
        color: #636363;
        padding-left: 10px;
        position: relative;
        &:not(:first-child) {
          margin-top: 10px;
        }
        &:after {
          content: '';
          position: absolute;
          top: 5px;
          left: 0;
          width: 3px;
          height: 3px;
          background: #005492;
          border-radius: 50%;
        }
      }
    }
  }
}
.info {
  position: relative;
  z-index: 3;
  &__head {
    display: flex;
    justify-content: center;
    align-items: center;

  }
  &__tab {
    width: 100%;
    height: 80px;
    font-weight: 900;
    font-size: 15px;
    line-height: 120%;
    color: #005492;
    background: #D1DDE5;
    border-radius: 4px 4px 0px 0px;
    &:not(:first-child) {
      margin-left: 2px;
    }
    &--active {
      background: #005492;
      color: #FFFFFF;
    }
    &:hover {
      background: #005492;
      color: #FFFFFF;
    }
  }
  &__content {
    display: none;
    padding: 60px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    &--active {
      display: block;
    }
    &-desc {
      p {
        font-size: 18px;
        line-height: 130%;
        color: #000000;
        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }
    &-text {
      font-size: 12px;
      line-height: 130%;
      color: #000000;
      margin-top: 30px;
    }
    &-subtitle {
      font-weight: 700;
      font-size: 18px;
      line-height: 100%;
      color: #000000;
    }
    &-nav {
      &:not(:first-child) {
        margin-top: 25px;
      }
    }
    &-list {
      margin-top: 25px;
      li {
        font-size: 18px;
        line-height: 100%;
        color: #636363;
        padding-left: 15px;
        position: relative;
        &:not(:first-child) {
          margin-top: 10px;
        }
        &:after {
          content: '';
          position: absolute;
          top: 8px;
          left: 0;
          width: 3px;
          height: 3px;
          background: #005492;
          border-radius: 50%;
        }
      }
    }
  }
  &__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 28px;
    &-group {
      &--100 {
        grid-column: 1/3;
      }
      input {
        width: 100%;
        height: 50px;
      }
      textarea {
        width: 100%;
        height: 172px;
      }
    }
    &-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-check {
      display: flex;
      align-items: center;
      position: relative;
      input {
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 100%;
        height: 100%;
        cursor: pointer;
        margin: 0;
        padding: 0;
        opacity: 0;
        &:checked ~ label {
          &:before {
            opacity: 1;
          }
        }
      }
      label {
        font-size: 12px;
        line-height: 100%;
        text-decoration-line: underline;
        color: #8F8F8F;
        padding-left: 37px;
        br {
          display: none;
        }
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          width: 22px;
          height: 22px;
          background: #EFEFEF;
          border-radius: 4px;
        }
        &:before {
          content: '';
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 6px;
          transform: translate(0, -50%);
          width: 11px;
          height: 9px;
          background-image: url("../img/check.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 11px 9px;
          opacity: 0;
        }
      }
    }
    &-btn {
      width: 132px;
      height: 56px;
    }
  }
  &__document {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px 130px;
    max-width: 870px;
    margin: 0 auto;
    &-item {
      display: flex;
      align-items: center;
      &:hover {
        .info__document-desc {
          color: #95db35;
        }
      }
    }
    &-icon {
      width: 55px;
      flex-shrink: 0;
      img {
        width: 100%;
      }
    }
    &-desc {
      font-size: 18px;
      line-height: 100%;
      color: #050505;
      margin-left: 20px;
    }
  }
}
.equipment2 {
  padding: 80px 0 100px;
  background: unset;
}

@media screen and (max-width: 1300px) {
  .equipment-main {
    padding: 160px 0 60px;
  }
}

@media screen and (max-width: 1200px) {
  .equipment-main {
    padding: 113px 0 60px;
  }
}

@media screen and (max-width: 1100px) {
  .content2 {
    &-img {
      width: 250px;
      height: 470px;
      right: 0;
    }
    &-img2 {
      width: 250px;
      height: 470px;
      top: 550px;
      left: 0;
      bottom: unset;
    }

  }
  .equipment-main {
    .gallery {
      &__small-wrapp {
        width: 107px;
      }
      &__small {
        max-height: 360px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .equipment-main {
    padding: 83px 0 60px;
    &__title {
      line-height: 120%;
    }
    &__inner {
      padding: 20px;
    }
    &__left {
      max-width: calc(100% - 355px);
    }
    &__right {
      max-width: 320px;
      margin-left: 35px;
    }
    .gallery {
      &__big {
        max-width: 300px;
      }
      &__small-wrapp {
        width: 85px;
        .swiper-button-prev {
          width: 25px;
          height: 25px;
          top: -15px;
        }
        .swiper-button-next {
          width: 25px;
          height: 25px;
          bottom: -35px;
        }
      }
      &__small {
        max-height: 300px;
      }
    }
    &__info {
      padding: 20px;
      margin-top: 20px;
      &-list {
        li {
          &:nth-child(7) ~ li {
            display: none;
          }
        }
      }
    }
  }
  .info {
    &__tab {
      height: 50px;
    }
    &__document {
      gap: 15px 60px;
    }
  }
  .equipment2 {
    padding: 50px 0 80px;
  }
}

@media screen and (max-width: 768px) {
  .equipment-main {
    &__inner {
      padding: 10px;
    }
    &__left {
      max-width: calc(100% - 250px);
    }
    &__right {
      max-width: 220px;
      margin-left: 30px;
    }
    .gallery {
      &__big {
        margin-right: 20px;
      }
      &__small-wrapp {
        width: 68px;
        .swiper-button-prev {
          width: 20px;
          height: 20px;
          top: -10px;
        }
        .swiper-button-next {
          width: 20px;
          height: 20px;
          bottom: -30px;
        }
      }
      &__small {
        max-height: 245px;
      }
    }
    &__info {
      padding: 15px;
      &-subtitle {
        font-size: 13px;
      }
      &-list {
        li {
          &:nth-child(4) ~ li {
            display: none;
          }
        }
      }
    }
  }
  .info {
    &__head {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2px;
    }
    &__content {
      &-desc {
        p {
          font-size: 16px;
          &:not(:first-child) {
            margin-top: 10px;
          }
        }
      }
      &-list {
        li {
          font-size: 16px;
        }
      }
    }
    &__form {
      gap: 12px;
      &-check {
        label {
          br {
            display: block;
          }
        }
      }
      &-btn {
        width: 118px;
        height: 48px;
      }
    }
    &__document {
      &-icon {
        width: 34px;
      }
      &-desc {
        font-size: 16px;
        margin-left: 12px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .equipment-main {
    &__inner {
      display: block;
    }
    &__left {
      max-width: 100%;
      width: 100%;
    }
    &__right {
      max-width: 100%;
      width: 100%;
      margin: 25px 0 0 0;
    }
    .gallery {
      display: block;
      max-height: 100%;
      &__big {
        width: 100%;
        max-width: 100%;
      }
      &__small-wrapp {
        width: 100%;
        .swiper-button-prev {
          width: 25px;
          height: 25px;
          top: 85%;
          left: -30px;
          transform: translate(0, -50%) rotate(-90deg);
        }
        .swiper-button-next {
          width: 25px;
          height: 25px;
          top: 85%;
          left: unset;
          right: -30px;
          transform: translate(0, -50%) rotate(-90deg);
        }
      }
      &__small-wrapp {
        max-width: 275px;
        margin: 10px auto 0;
      }
      &__small {
        max-height: 80px;
      }
    }
  }
  .info {
    &__tab {
      font-size: 13px;
    }
    &__content {
      padding: 15px;
      &-desc {
        p {
          font-size: 14px;
        }
      }
      &-text {
        margin-top: 15px;
        font-size: 10px;
      }
      &-nav {
        &:not(:first-child) {
          margin-top: 15px;
        }
      }
      &-subtitle {
        font-size: 16px;
      }
      &-list {
        margin-top: 15px;
        li {
          font-size: 14px;
        }
      }
    }
    &__form {
      grid-template-columns: 1fr;
      &-group {
        &--100 {
          grid-column: 1/2;
        }
        input {
          height: 40px;
        }
        textarea {
          height: 105px;
        }
      }
      &-bottom {
        display: block;
      }
      &-btn {
        margin-top: 15px;
      }
    }
    &__document {
      grid-template-columns: 1fr;
      gap: 15px;
    }
  }
  .equipment2 {
    padding: 30px 0 70px;
  }
}


@media screen and (max-width: 400px) {
  .equipment-main {
    .gallery {
      &__small-wrapp {
        .swiper-slide__btns {
          display: flex;
          align-items: center;
          margin-top: 60px;
        }
        .swiper-button-prev {
          position: static;
        }
        .swiper-button-next {
          position: static;
          margin-left: 10px;
        }
      }
    }
  }
}