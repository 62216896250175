*,
*::before,
*::after {
  box-sizing: inherit;
}

* {
  box-sizing: border-box;
}

body,
div,
p,
h1,
h2,
h3,
h4,
h5,
ul,
li,
a,
form,
label {
  margin: 0;
  padding: 0;
  border: 0;
  text-decoration: none;
  line-height: 1;
  box-sizing: border-box;
}

button,
input,
textarea {
  outline: none !important;
}

button {
  font-family: 'Montserrat';
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}

input[type=text]:focus,
input[type=file]:focus,
input[type=checkbox]:focus,
input[type=radio]:focus,
input:active {
  outline: none !important;
}

html {
  height: 100%;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  height: 100%;
  overflow-x: hidden;
  background: #fff;
}
.fixed-body {
  overflow: hidden;
}
.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
img {
  margin: 0;
  padding: 0;
  height: auto;
  object-fit: cover;
}

a {
  color: inherit;
  text-decoration: none;
  transition: 0.5s;
}

a:hover {

}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1230px;
}